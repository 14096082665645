document.addEventListener('click', function (event) {
  if (!event.target.matches('#autogenerate_instance_name')) return;
  event.preventDefault();

  var client = document.querySelector("#instance_client_name");
  var product = document.querySelector("#instance_product_id");
  var name = document.querySelector("#instance_name");

  if (client.value.slice(-1) == 's'){ // An attempt at getting the grammer right, verified by Emma N
    var joiner = "' "
  }else{
    var joiner = "'s "
  }

  name.value = client.value + joiner + product.options[product.selectedIndex].text

}, false);
