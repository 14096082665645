// Only activated on the Edit/New user form.

import SlimSelect from 'slim-select'
import 'slim-select/dist/slimselect.min.css'; // this shouldn't be needed but is - TODO: work out why!

document.addEventListener('DOMContentLoaded', function() {
  if (document.getElementById("user_user_type")){
    new SlimSelect({
      // Used in the uder edit/new form to set which instances are assigned to them.
      select: '#instances_select'
    });
    UpdateInstanceListVisibility();
    document.getElementById("user_user_type").onchange = function(){
      UpdateInstanceListVisibility();
    };
  };
});

function UpdateInstanceListVisibility(){

  // The instance list is ignored by the backend unless the user type is client, as other users always see all instances. So hide it in the UI to save confusion.

  var select = document.getElementById("user_user_type");
  var instance_list = document.getElementsByClassName("user_form_list_of_instances")[0];

  if (select.value == 'client'){
    instance_list.classList.remove('hide');
  }else{
    instance_list.classList.add('hide');
  }

};
