document.addEventListener('DOMContentLoaded', function() {
  if (document.getElementById("instance_product_id")){
    UpdateRegionsOnInstanceEditAndNewPages();
    document.getElementById("instance_product_id").onchange = function(){
      UpdateRegionsOnInstanceEditAndNewPages();
    };
  }
});

function UpdateRegionsOnInstanceEditAndNewPages(){

  // Using the data associated with the Product select input, create the checkboxes to show available regions for this instance.

  var select = document.getElementById("instance_product_id");
  var selectedProductID = select.value;
  var selectedProduct = document.querySelector(".product_" + selectedProductID );

  var regions = selectedProduct.dataset.regions.split(',');

  if (selectedProduct.dataset.selected_regions){
    var pre_selected_regions = selectedProduct.dataset.selected_regions.split(',');
  }else{
    var pre_selected_regions = [];
  }

  var checkboxes = "";

  for (var i=0; i<regions.length; i++){
    var checked = '';
    if (pre_selected_regions.includes(regions[i])){
      checked = "checked = 'checked'";
    }

    var tag_flag = '<span class="tag flag region_' + regions[i] + '"></span>';

    checkboxes = checkboxes + "<label class='label'>          <input type='checkbox' name='regions[]' id='regions_" + regions[i] +"' value='" + regions[i] + "' " + checked + " > " + tag_flag + " " + regions[i].toUpperCase() + "</label>";
  }

  document.querySelector('.regions_checkboxes_from_js').innerHTML = checkboxes;
};
