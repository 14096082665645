import { Controller } from 'stimulus';
import MarkdownIt from 'markdown-it'

export default class extends Controller {
  connect() {
    const markdown = this.element.getAttribute('data-markdown');
    console.log(markdown);
    const md = new MarkdownIt();
    console.log(md.render(markdown));
    this.element.innerHTML = md.render(markdown);
  }
}
