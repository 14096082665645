import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['iframe'];

  connect() {
    const iframeEl = this.iframeTargets[0];
    const iframeBody = iframeEl.contentWindow.document.getElementsByTagName('body')[0];
    const script = iframeEl.contentWindow.document.createElement('script');
    script.src = iframeEl.getAttribute('data-url');
    iframeBody.appendChild(script);

    window.addEventListener('resize', () => {
      this.setIframeHeight(iframeEl);
    });
    window.addEventListener('tabChange', () => {
      this.setIframeHeight(iframeEl);
    });
    this.setIframeHeight(iframeEl);
  }

  setIframeHeight(iframeEl) {
    const offset = 100;
    iframeEl.height = 0;
    iframeEl.height = iframeEl.contentWindow.document.body.scrollHeight + offset;
  }
}