import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['tag', 'input'];

  get tagsInput() {
    return this.inputTargets[0];
  }

  connect() {
    this.setActiveTags();
  }

  tagClick(event) {
    const tagElement = event.target;
    const tagValue = tagElement.getAttribute('data-value');
    const activeTags = this.arrayFromInput(this.tagsInput.value);
    if (activeTags.indexOf(tagValue) === -1) {
      tagElement.classList.add('tag--selected');
      activeTags.push(tagValue);
      this.tagsInput.value = this.arrayToInput(activeTags);
    } else {
      tagElement.classList.remove('tag--selected');
      this.tagsInput.value = this.arrayToInput(activeTags.filter(tag => tag !== tagValue));
    }
  }

  arrayFromInput(value) {
    return value.split(',').map(value => value.trim()).filter(v => v !== '');
  }

  arrayToInput(value) {
    return value.join(', ')
  }

  inputInput() {
    this.setActiveTags();
  }

  setActiveTags() {
    const activeTags = this.arrayFromInput(this.tagsInput.value);
    this.tagTargets.forEach(tag => {
      tag.classList.remove('tag--selected');
    });
    activeTags.forEach((activeTag) => {
      this.tagTargets.forEach((tagTarget) => {
        const tagValue = tagTarget.getAttribute('data-value');
        if (activeTag === tagValue) {
          tagTarget.classList.add('tag--selected');
        }
      });
    });
  }
}