// Lifted from https://gitlab.ahc.uk.com/lifetime/lifetime-gallagher-products/blob/master/source/javascripts/site.js

import { Controller } from 'stimulus';

const pricing = {
  clientTypes: {
    name: 'Client type',
    employer: {
      name: 'Employer',
      uk: {
        frontend: 50,
        engine: 25,
      },
      us: {
        frontend: 75,
        engine: 50,
      },
      au: {
        frontend: 100,
        engine: 50,
      },
    },
    provider: {
      name: 'Provider',
      uk: {
        frontend: 200,
        engine: 100,
      },
      us: {
        frontend: 300,
        engine: 150,
      },
      au: {
        frontend: 400,
        engine: 200,
      },
    },
  },
  licenceTypes: {
    name: 'Licence type',
    payMonthly: {
      name: 'Pay monthly',
      setupMultiplier: 12,
    },
    twoYearContract: {
      name: '2 year contract',
      // Setup cost is free for 2 year contract
      setupMultiplier: 0,
    },
  },
  products: {
    name: 'Product',
    costOfDelay: {
      name: 'Cost of Delay',
      usesFrontEnd: true,
      usesEngine: false,
      regions: ['UK', 'US', 'AU'],
    },
    dcModeller: {
      name: 'DC Modeller',
      usesFrontEnd: true,
      usesEngine: true,
      regions: ['UK'],
    },
    debt: {
      name: 'Debt',
      usesFrontEnd: true,
      usesEngine: false,
      regions: ['UK', 'US', 'AU'],
    },
    hittingTheTarget: {
      name: 'Hitting the Target',
      usesFrontEnd: true,
      usesEngine: false,
      regions: ['UK'],
    },
    smallChangeBigSavings: {
      name: 'Small Change, Big Savings',
      usesFrontEnd: true,
      usesEngine: false,
      regions: ['UK', 'US', 'AU'],
    },
    supermodeller: {
      name: 'Supermodeller',
      usesFrontEnd: true,
      usesEngine: true,
      regions: ['AU'],
    },
    pension_gap_planner: {
      name: 'Pension Gap Planner',
      usesFrontEnd: true,
      usesEngine: true,
      regions: ['UK'],
    },
  },
};

const pricing_full = {
  clientTypes: {
    name: 'Client type',
    employer: {
      name: 'Employer',
      uk: {
        frontend: 50,
        engine: 25,
      },
      us: {
        frontend: 75,
        engine: 50,
      },
      au: {
        frontend: 100,
        engine: 50,
      },
    },
    provider: {
      name: 'Provider',
      uk: {
        frontend: 200,
        engine: 100,
      },
      us: {
        frontend: 300,
        engine: 150,
      },
      au: {
        frontend: 400,
        engine: 200,
      },
    },
  },
  licenceTypes: {
    name: 'Licence type',
    payMonthly: {
      name: 'Pay monthly',
      setupMultiplier: 12,
    },
    '2YearContract': {
      name: '2 year contract',
      setupMultiplier: 0,
    },
  },
  products: {
    name: 'Product',
    compoundInterest: {
      name: 'Compound Interest',
      usesFrontEnd: true,
      usesEngine: false,
      regions: ['UK', 'US', 'AU'],
    },
    contribution: {
      name: 'Contribution',
      usesFrontEnd: true,
      usesEngine: false,
      regions: ['UK'],
    },
    costOfDelay: {
      name: 'Cost of Delay',
      usesFrontEnd: true,
      usesEngine: false,
      regions: ['UK', 'US', 'AU'],
    },
    dcusesEngine: {
      name: 'DC usesEngine',
      usesFrontEnd: false,
      usesEngine: true,
      regions: ['UK'],
    },
    dcModeller: {
      name: 'DC Modeller',
      usesFrontEnd: true,
      usesEngine: true,
      regions: ['UK'],
    },
    debt: {
      name: 'Debt',
      usesFrontEnd: true,
      usesEngine: false,
      regions: ['UK', 'US', 'AU'],
    },
    firstHomeSuperSaverScheme: {
      name: 'First Home Super Saver Scheme',
      usesFrontEnd: true,
      usesEngine: false,
      regions: ['AU'],
    },
    hittingTheTarget: {
      name: 'Hitting the Target',
      usesFrontEnd: true,
      usesEngine: false,
      regions: ['UK'],
    },
    payCheck: {
      name: 'Pay Check',
      usesFrontEnd: true,
      usesEngine: true,
      regions: ['US'],
    },
    requiredMinimumDistribution: {
      name: 'Required Minimum Distribution',
      usesFrontEnd: true,
      usesEngine: false,
      regions: ['US'],
    },
    retirementusesEngine: {
      name: 'Retirement usesEngine',
      usesFrontEnd: false,
      usesEngine: true,
      regions: ['US'],
    },
    retirementIncome: {
      name: 'Retirement Income',
      usesFrontEnd: true,
      usesEngine: true,
      regions: ['US'],
    },
    retirementLifestyle: {
      name: 'Retirement Lifestyle',
      usesFrontEnd: true,
      usesEngine: false,
      regions: ['UK', 'US', 'AU'],
    },
    savingsBoost: {
      name: 'Savings Boost',
      usesFrontEnd: true,
      usesEngine: false,
      regions: ['UK', 'US', 'AU'],
    },
    smallChangeBigSavings: {
      name: 'Small Change, Big Savings',
      usesFrontEnd: true,
      usesEngine: false,
      regions: ['UK', 'US', 'AU'],
    },
    superEngine: {
      name: 'SuperusesEngine',
      usesFrontEnd: false,
      usesEngine: true,
      regions: ['AU'],
    },
    supermodeller: {
      name: 'Supermodeller',
      usesFrontEnd: true,
      usesEngine: true,
      regions: ['AU'],
    },
  },
};

export default class extends Controller {

  static targets = [
    'calculateButton',
  ]

  connect() {
    this.populateSelect('product', pricing.products);
    this.populateSelect('client-type', pricing.clientTypes);
    this.populateSelect('licence-type', pricing.licenceTypes);
  }

  costs(chosenProduct, chosenClientType, chosenLicenceType) {
    const product = pricing.products[chosenProduct];
    const clientType = pricing.clientTypes[chosenClientType];
    const licenceType = pricing.licenceTypes[chosenLicenceType];

    if (product === undefined) {
      console.error(`Cannot find product: ${chosenProduct}`);
      return {};
    }

    if (clientType === undefined) {
      console.error(`Cannot find client type: ${chosenClientType}`);
      return {};
    }

    if (licenceType === undefined) {
      console.error(`Cannot find licence Type: ${chosenLicenceType}`);
      return {};
    }

    return {
      uk: {
        setup: this.getSetupCost(product, clientType, licenceType, 'uk'),
        licence: this.getLicenceCost(product, clientType, 'uk'),
        currency: '£',
      },
      us: {
        setup: this.getSetupCost(product, clientType, licenceType, 'us'),
        licence: this.getLicenceCost(product, clientType, 'us'),
        currency: '$',
      },
      au: {
        setup: this.getSetupCost(product, clientType, licenceType, 'au'),
        licence: this.getLicenceCost(product, clientType, 'au'),
        currency: '$',
      },
    };
  }

  

  getSetupCost(product, clientType, licenceType, region) {
    const { setupMultiplier } = licenceType;
    const { frontend } = clientType[region];

    if (this.isInRegion(product, region)) {
      return setupMultiplier * frontend;
    }

    return 0;
  }

  getLicenceCost(product, clientType, region) {
    const { usesFrontEnd, usesEngine } = product;
    const { frontend, engine } = clientType[region];

    if (this.isInRegion(product, region)) {
      return (usesFrontEnd ? frontend : 0) + (usesEngine ? engine : 0);
    }

    return 0;
  }

  isInRegion(product, region) {
    const { regions } = product;
    return regions.includes(region.toUpperCase());
  }

    /* DOM functions for pricing calculator */

  populateSelect(dropdownId, options) {
    const dropdown = document.getElementById(dropdownId);
    dropdown.length = 0;

    const defaultOption = document.createElement('option');
    defaultOption.text = `Choose ${options.name}`;

    dropdown.add(defaultOption);
    dropdown.selectedIndex = 0;

    let option;
    const keys = Object.keys(options);
    for (let i = 0; i < keys.length; i += 1) {
      if (keys[i] !== 'name') {
        option = document.createElement('option');
        option.text = options[keys[i]].name;
        option.value = keys[i];
        dropdown.add(option);
      }
    }
  }

  calculateCosts() {
    const product = document.getElementById('product').value;
    const clientType = document.getElementById('client-type').value;
    const licenceType = document.getElementById('licence-type').value;

    let pricing;

    if (product.toLowerCase().includes('choose')
      || clientType.toLowerCase().includes('choose')
      || licenceType.toLowerCase().includes('choose')) {

      pricing = {};
    } else {
      pricing = this.costs(product, clientType, licenceType);
      document.getElementById('uk-setup').innerHTML = `${pricing.uk.currency}${pricing.uk.setup}`;
      document.getElementById('uk-licence').innerHTML = `${pricing.uk.currency}${pricing.uk.licence}`;
      document.getElementById('us-setup').innerHTML = `${pricing.us.currency}${pricing.us.setup}`;
      document.getElementById('us-licence').innerHTML = `${pricing.us.currency}${pricing.us.licence}`;
      document.getElementById('au-setup').innerHTML = `${pricing.au.currency}${pricing.au.setup}`;
      document.getElementById('au-licence').innerHTML = `${pricing.au.currency}${pricing.au.licence}`;
    }
  }

  calculateCostsFrontend() {
    const product = document.getElementById('product').value;
    const clientType = document.getElementById('client-type').value;
    const licenceType = document.getElementById('licence-type').value;

    let pricing;

    if (product.toLowerCase().includes('choose')
      || clientType.toLowerCase().includes('choose')
      || licenceType.toLowerCase().includes('choose')) {

      pricing = {};
    } else {
      pricing = this.costs(product, clientType, licenceType);
      document.getElementById('cost-table').scrollIntoView({ behavior: 'smooth', block: 'center' });
      // Populate table
      Object.keys(pricing).forEach(function(country) {
        // Hide row if licence costs are zero as this means they're not available in the region
        document.getElementById(country+'-row').style.display = pricing[country].licence > 0 ? 'table-row' : 'none';

        // Populate figures
        document.getElementById(country+'-setup').innerHTML = `${pricing[country].currency}${pricing[country].setup}`;
        document.getElementById(country+'-licence').innerHTML = `${pricing[country].currency}${pricing[country].licence}`;
      });
    }
  }
}

