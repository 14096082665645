import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['menuButton', 'navWrapper'];

  toggleMenu() {
    this.toggleActive(this.menuButtonTargets[0]);
    this.toggleActive(this.navWrapperTargets[0]);
  }

  toggleActive(element, modifier = 'active') {
    element.classList.toggle(`${element.className.split(' ')[0]}--${modifier}`)
  }

  scrollToMainContent() {
    this.element.nextElementSibling.scrollIntoView( {
      behavior: "smooth",
    });
  }
}
