import Glide, { Autoplay, Controls } from '@glidejs/glide/dist/glide.modular.esm';
import { Controller } from 'stimulus';

const seconds = (s) => s * 1000;

const glidejsOptions = {
  autoplay: seconds(10),
  hoverpause: false,
};

const glidejsModules = {
  Autoplay,
  Controls,
};

export default class extends Controller {
  static targets = ['carousel']

  connect() {
    const glideInstance = new Glide(this.element, glidejsOptions);
    glideInstance.mount(glidejsModules);
  }
}
