import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['tabLink', 'tabContent'];

  connect() {
    this.toggleActive(this.getDataKey(this.tabLinkTargets[0]));
  }

  handleClick(event) {
    this.toggleActive(this.getDataKey(event.target))
  }

  toggleActive(key) {
    this.tabLinkTargets.forEach(target => {
      target.parentElement.classList.remove('is-active');
    })

    this.tabContentTargets.forEach(target => {
      target.classList.remove('is-active');
    })

    const activeLink = this.tabLinkTargets.find(tabLink => this.getDataKey(tabLink) === key);
    activeLink.parentElement.classList.add('is-active');


    const activeContent = this.tabContentTargets.find(tabContent => this.getDataKey(tabContent) === key);
    activeContent.classList.add('is-active');

    window.dispatchEvent(
      new Event(this.getEventName())
    );
  }

  getDataKey(element) {
    return element.getAttribute('data-key');
  }

  getEventName() {
    return this.element.getAttribute('data-event-name');
  }
}
