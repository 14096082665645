import '@stimulus/polyfills';
import { Application } from 'stimulus';
import { definitionsFromContext } from 'stimulus/webpack-helpers';
import Rails from '@rails/ujs';

import '../components';
import '../stylesheets/application.scss';

Rails.start();

require("@rails/activestorage").start();
require("channels");
require("region_checkboxes");
require("user_form");
require("instance_name_generator");
require("client_name_picker");

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
const images = require.context('../images', true);
// eslint-disable-next-line no-unused-vars
const imagePath = (name) => images(name, true);

const application = Application.start();
const componentsContext = require.context('../../components', true, /(.*)\/.+\.js$/);
application.load(
  definitionsFromContext(componentsContext),
);
